
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";
import API from "@/api/API";
import store from "@/store";

export default Vue.extend({
  name: "EditSpecialists",

  props: {
    showEditSpecialists: {
      type: Boolean,
      default: true
    },
    orderId: {
      required: true
    }
  },

  data: () => ({
    rules,
    loading: false as boolean,
    show: false as boolean,
    model: {},
    users: [] as Array<any>,
    assistants: [] as Array<any>,
    oldAssistants: [] as Array<any>,
    errorMessages: {} as { [value: string]: Array<string> }
  }),

  watch: {
    orderId: {
      immediate: true,
      handler() {
        if (this.orderId && this.showEditSpecialists) {
          this.show = this.showEditSpecialists;
          this.loadData();
        }
      }
    }
  },

  computed: {
    isValidPercent() {
      return this.totalPercent < 0 || this.totalPercent > 100;
    },
    totalPercent() {
      return this.assistants.reduce(
        (total: number, { percent }: { percent: number }) => {
          if (Number(percent)) {
            return total + Number(percent);
          }
          return total;
        },
        0
      );
    },
    managerPercent() {
      return 100 - this.totalPercent;
    }
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        // todo изменить endpoint как и в доугиз местах
        const users = await API.users().getSpecialistsByOrder(
          Number(this.orderId)
        );
        const assistants = await API.orders().getAssistants(
          Number(this.orderId)
        );

        this.users = users;
        this.assistants = assistants;
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as Vue).validate()) {
          this.assistants = await this.$API
            .orders()
            .updateAssistants(Number(this.orderId), {
              experts: this.assistants
            });

          this.$emit("change", false);

          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    toggleDialog() {
      this.show = false;
      this.$emit("change", false);
    },
    async addSpecialist(): Promise<void> {
      this.assistants.push({});
    },
    async removeSpecialist(index: number): Promise<void> {
      this.assistants.splice(index, 1);
    }
  }
});
