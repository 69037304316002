var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"transparent",class:{ 'full-size': _vm.fullSize },attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('div',{staticClass:"d-flex align-center",class:{ 'full-size flex-column pb-2': _vm.fullSize },staticStyle:{"min-width":"250px"}},[_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"max-width":"500","bottom":"","disabled":_vm.fullSize},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"}},'span',attrs,false),on),[_c('v-btn',{attrs:{"text":"","x-small":"","disabled":!_vm.item.highlight || _vm.item.highlight.event_type === 'checked'},on:{"click":function($event){return _vm.makeAsRead()}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-check-all ")]),_c('div',{staticClass:"header text--accent-1 ml-1"},[_vm._v(" "+_vm._s(_vm.$t("orders.buttons.make-as-read"))+" ")])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("orders.buttons.make-as-read")))])])],1),_c('div',{staticClass:"d-flex"},[(!_vm.isBookkeeper)?_c('v-tooltip',{attrs:{"max-width":"500","bottom":"","disabled":_vm.fullSize},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"}},'span',attrs,false),on),[_c('v-btn',{attrs:{"link":"","to":("/orders/edit/" + (_vm.item.id)),"x-small":"","fab":!_vm.fullSize,"text":"","disabled":!_vm.canView('edit')}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-pencil ")]),_c('div',{staticClass:"header ml-1"},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.edit"))+" ")])],1)],1)]}}],null,false,2575357505)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("global_buttons.edit")))])]):_vm._e()],1),(_vm.isAta)?_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"max-width":"500","bottom":"","disabled":_vm.fullSize},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"}},'span',attrs,false),on),[_c('v-btn',{attrs:{"disabled":_vm.disabled,"x-small":"","fab":!_vm.fullSize,"text":""},on:{"click":function($event){return _vm.openSettings(_vm.item)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-printer ")]),_c('div',{staticClass:"header ml-1"},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.open_print"))+" ")])],1)],1)]}}],null,false,1530619319)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("global_buttons.open_print")))])])],1):_vm._e(),(_vm.canView('edit_specialists'))?_c('div',{staticClass:"d-flex"},[(!_vm.isBookkeeper)?_c('v-tooltip',{attrs:{"max-width":"500","bottom":"","disabled":_vm.fullSize},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.isBookkeeper)?_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"}},'span',attrs,false),on),[_c('v-btn',{attrs:{"x-small":"","fab":!_vm.fullSize,"text":""},on:{"click":function($event){return _vm.openAssistants(_vm.item)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-account-cog ")]),_c('div',{staticClass:"header ml-1"},[_vm._v(" "+_vm._s(_vm.$t("orders.form.header.edit_specialists"))+" ")])],1)],1):_vm._e()]}}],null,false,4105327043)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("orders.form.header.edit_specialists")))])]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"max-width":"500","bottom":"","disabled":_vm.fullSize},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"}},'span',attrs,false),on),[_c('v-btn',{attrs:{"disabled":!_vm.canView('history'),"link":"","to":("/orders/history/" + (_vm.item.id)),"x-small":"","fab":!_vm.fullSize,"text":""}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-clipboard-list-outline ")]),_c('div',{staticClass:"header ml-1"},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.history"))+" ")])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("global_buttons.history")))])])],1),(_vm.fullSize)?_c('div',{staticClass:"d-flex"},[(_vm.selectedText)?_c('v-btn',{staticClass:"full-width",attrs:{"text":"","small":""},on:{"click":function($event){return _vm.copyToClipboard(_vm.selectedText)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-content-copy ")]),_c('div',{staticClass:"text--accent-1 ml-1 full-width text-left"},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.copy-to-clipboard"))+" ")])],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"d-flex"},[(!_vm.isBookkeeper)?_c('v-tooltip',{attrs:{"max-width":"500","bottom":"","disabled":_vm.fullSize},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"250px"}},'span',attrs,false),on),[_c('v-btn',{directives:[{name:"confirm",rawName:"v-confirm",value:({
                  title: _vm.$t('global_alert.deletion_title'),
                  message: _vm.$t('global_alert.deletion_text'),
                  callback: function () {
                    _vm.deleteRow(_vm.item.id);
                  }
                }),expression:"{\n                  title: $t('global_alert.deletion_title'),\n                  message: $t('global_alert.deletion_text'),\n                  callback: () => {\n                    deleteRow(item.id);\n                  }\n                }"}],attrs:{"disabled":!(_vm.canDeleteOrder(_vm.item.status.alias) && _vm.canView('delete')),"x-small":"","color":"error","text":""}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-delete ")]),_c('div',{staticClass:"header ml-1"},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.delete"))+" ")])],1)],1)]}}],null,false,1668969830)},[_c('span',[_vm._v(_vm._s(_vm.$t("global_buttons.delete")))])]):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }