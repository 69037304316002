var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"elevation-4 pa-4",attrs:{"width":"100%"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-form',{ref:"form",staticClass:"d-flex flex-column justify-center",attrs:{"lazy-validation":""}},[_c('v-row',{staticClass:"mx-2 py-1"},[_c('v-col',{attrs:{"col":"12"}},[_c('div',{staticClass:"full-width text-h6"},[_c('span',[_vm._v(" "+_vm._s(((_vm.$t("orders.form.fields.manager_percent")) + ": "))+" ")]),_c('span',{class:{ 'error--text': _vm.isValidPercent }},[_vm._v(_vm._s(_vm.managerPercent)+"%")])])])],1),(!_vm.loading)?[_vm._l((_vm.assistants),function(item,index){return _c('v-row',{key:index,staticClass:"py-1 mx-2 my-0"},[_c('v-col',{staticClass:"py-1"},[_c('v-select',{attrs:{"clearable":"","items":_vm.users,"rules":[
                  _vm.rules.required(),
                  _vm.rules.unique(_vm.assistants, item.user_id, 'user_id')
                ],"label":((_vm.$t('orders.form.fields.specialist')) + "*")},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}},model:{value:(item.user_id),callback:function ($$v) {_vm.$set(item, "user_id", $$v)},expression:"item.user_id"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required(), _vm.rules.minNumber(1)],"label":((_vm.$t('orders.form.fields.percent')) + "*"),"error-messages":_vm.errorMessages[(index + ".percent")]},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit.apply(null, arguments)}},model:{value:(item.percent),callback:function ($$v) {_vm.$set(item, "percent", $$v)},expression:"item.percent"}})],1),_c('v-col',{staticClass:"py-1 d-flex align-center justify-center",attrs:{"cols":"1"}},[_c('v-btn',{directives:[{name:"confirm",rawName:"v-confirm",value:({
                  title: _vm.$t('global_alert.deletion_title'),
                  message: _vm.$t('global_alert.deletion_text'),
                  callback: function () {
                    _vm.removeSpecialist(index);
                  }
                }),expression:"{\n                  title: $t('global_alert.deletion_title'),\n                  message: $t('global_alert.deletion_text'),\n                  callback: () => {\n                    removeSpecialist(index);\n                  }\n                }"}],attrs:{"x-small":"","color":"error","icon":"","depressed":""}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)}),_c('v-row',{staticClass:"py-1 mx-2"},[_c('v-col',{staticClass:"py-1 d-flex justify-end",attrs:{"cols":"12"}},[_c('div',[_c('v-btn',{staticClass:"full-width",attrs:{"color":"primary","text":"","small":"","title":_vm.$t('global_buttons.create')},on:{"click":function($event){return _vm.addSpecialist()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('span',[_vm._v(_vm._s(_vm.$t("orders.buttons.add_specialist")))])],1)],1)]),_c('v-col',{staticClass:"text-center my-0  mx-2 py-3",attrs:{"cols":"12"}},[_c('v-expand-transition',[(_vm.isValidPercent)?_c('div',[_c('span',{staticClass:"error--text"},[_vm._v(" "+_vm._s(_vm.$t("orders.errors.manager_percent"))+" ")])]):_vm._e()])],1)],1)]:_c('div',{staticClass:"full-width d-flex align-center justify-center pt-5"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),_c('v-row',{staticClass:"actions mt-5"},[_c('v-col',{staticClass:"pa-2 d-flex align-center"},[_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t("global_validation.is_required"))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.toggleDialog()}}},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.cancel"))+" ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":_vm.isValidPercent,"color":"primary","depressed":""},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("global_buttons.save"))+" ")])],1)],1)],2)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }