
import Vue from "vue";
import { values } from "lodash";
export default Vue.extend({
  name: "FastSelect",

  props: {
    value: {
      required: true
    },
    values: {
      type: Array,
      required: true
    },
    filterKey: {
      type: String,
      required: true
    },
    label: {
      required: false
    },
    title: {
      type: String,
      required: false
    },
    isAta: {
      required: false,
      type: Boolean
    }
  },

  data: () => ({
    model: null as any
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        //@ts-ignore
        this.model = this.value;
      }
    }
  },

  computed: {
    isAtaSelected() {
      if (this.isAta) {
        return this.values.slice(0, 3);
      }
      return this.values;
    }
  },

  methods: {
    changeValue() {
      this.$emit("change", this.filterKey);
      this.$emit("input", this.model);
    }
  }
});
